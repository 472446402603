import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Select,
    MenuItem,
    TextField,
    Box,
    Button,
} from '@mui/material';

export interface RowData {
    meta: string;
    acao: string;
    colaborador: string;
    departamento: string;
    prazoMes: string;
    prazoAno: string;
    porcentagem: number;
    status: string;
}

interface TabelaDeTarefasProps {
    metas: string[];
    colaboradores: string[];
    departamentos: string[];
}

const TabelaDeTarefas: React.FC<TabelaDeTarefasProps> = ({ metas, colaboradores, departamentos }) => {
    const [rows, setRows] = useState<RowData[]>([
        { meta: '', acao: '', colaborador: '', departamento: '', prazoMes: '', prazoAno: '', porcentagem: 0, status: '' },
    ]);

    // Função para atualizar o status com base no valor de porcentagem, prazoMes e prazoAno
    const updateStatus = (index: number) => {
        const updatedRows = [...rows];
        const row = updatedRows[index];
        const { prazoMes, prazoAno, porcentagem } = row;

        // Verificar o status com base na porcentagem
        if (porcentagem === 100) {
            updatedRows[index].status = 'Concluído';
        } else if (prazoMes && prazoAno) {
            const currentDate = new Date();
            const prazoDate = new Date(parseInt(prazoAno), parseInt(prazoMes) - 1);
            const diffTime = prazoDate.getTime() - currentDate.getTime();
            const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));

            if (diffMonths > 0) {
                updatedRows[index].status = `Faltam ${diffMonths} meses`;
            } else {
                updatedRows[index].status = `Atrasado ${Math.abs(diffMonths)} meses`;
            }
        }
        setRows(updatedRows);
    };

    const handleChange = (index: number, field: keyof RowData, value: string | number) => {
        const updatedRows = [...rows];
    
        // Ajuste aqui para garantir que a tipagem do valor seja compatível com cada tipo do campo
        if (field === 'porcentagem') {
            updatedRows[index][field] = value as number;
        } else {
            updatedRows[index][field] = value as string;
        }
    
        // Atualizar o status sempre que um dos campos relevantes for alterado
        updateStatus(index);
    
        // Atualizar o estado das linhas
        setRows(updatedRows);
    };    

    // Função para formatar e garantir que o valor da porcentagem esteja entre 0 e 100
    const handlePorcentagemChange = (index: number, value: string) => {
        let parsedValue = parseInt(value);

        // Garantir que o valor seja entre 0 e 100
        if (parsedValue < 0) parsedValue = 0;
        if (parsedValue > 100) parsedValue = 100;

        // Atualizar o valor de porcentagem
        setRows((prevRows) => {
            const updatedRows = [...prevRows];
            updatedRows[index].porcentagem = parsedValue;

            // Atualizar o status sempre que a porcentagem for alterada
            updateStatus(index);

            return updatedRows;
        });
    };

    // Função para garantir que o valor do ano seja um número inteiro de 4 dígitos
    const handleAnoChange = (index: number, value: string) => {
        // Remover caracteres não numéricos e garantir que o ano tenha exatamente 4 dígitos
        const numericValue = value.replace(/\D/g, '').slice(0, 4);

        setRows((prevRows) => {
            const updatedRows = [...prevRows];
            updatedRows[index].prazoAno = numericValue;

            // Atualizar o status sempre que o prazoAno for alterado
            updateStatus(index);

            return updatedRows;
        });
    };

    // Função para adicionar uma nova linha vazia
    const addNewRow = () => {
        setRows((prevRows) => [
            ...prevRows,
            { meta: '', acao: '', colaborador: '', departamento: '', prazoMes: '', prazoAno: '', porcentagem: 0, status: '' },
        ]);
    };

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Meta</TableCell>
                            <TableCell sx={{ width: '25%' }}>Ação</TableCell>
                            <TableCell>Colaborador</TableCell>
                            <TableCell>Departamento</TableCell>
                            <TableCell>Prazo (Mês)</TableCell>
                            <TableCell sx={{ width: '10%' }}>Prazo (Ano)</TableCell>
                            <TableCell sx={{ width: '10%' }}>Porcentagem</TableCell>
                            <TableCell sx={{ width: '20%', wordWrap: 'break-word' }}>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Select
                                        fullWidth
                                        value={row.meta}
                                        onChange={(e) => handleChange(index, 'meta', e.target.value)}
                                    >
                                        <MenuItem value="">
                                            <em>Selecionar</em>
                                        </MenuItem>
                                        {metas.map((meta) => (
                                            <MenuItem key={meta} value={meta}>
                                                {meta}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        value={row.acao}
                                        onChange={(e) => handleChange(index, 'acao', e.target.value)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Select
                                        fullWidth
                                        value={row.colaborador}
                                        onChange={(e) => handleChange(index, 'colaborador', e.target.value)}
                                    >
                                        <MenuItem value="">
                                            <em>Selecionar</em>
                                        </MenuItem>
                                        {colaboradores.map((colaborador) => (
                                            <MenuItem key={colaborador} value={colaborador}>
                                                {colaborador}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        fullWidth
                                        value={row.departamento}
                                        onChange={(e) => handleChange(index, 'departamento', e.target.value)}
                                    >
                                        <MenuItem value="">
                                            <em>Selecionar</em>
                                        </MenuItem>
                                        {departamentos.map((departamento) => (
                                            <MenuItem key={departamento} value={departamento}>
                                                {departamento}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        fullWidth
                                        value={row.prazoMes}
                                        onChange={(e) => handleChange(index, 'prazoMes', e.target.value)}
                                    >
                                        <MenuItem value="">
                                            <em>Selecionar</em>
                                        </MenuItem>
                                        {Array.from({ length: 12 }, (_, i) => i + 1).map((mes) => (
                                            <MenuItem key={mes} value={mes}>
                                                {mes}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        value={row.prazoAno}
                                        onChange={(e) => handleAnoChange(index, e.target.value)}
                                        inputProps={{ maxLength: 4 }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        value={row.porcentagem}
                                        onChange={(e) => handlePorcentagemChange(index, e.target.value)}
                                        InputProps={{
                                            endAdornment: <span>%</span>, // Adicionando o símbolo de % no final
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        value={row.status}
                                        onChange={(e) => handleChange(index, 'status', e.target.value)}
                                        disabled
                                        sx={{ wordWrap: 'break-word', whiteSpace: 'normal' }} // Quebra de linha no status
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Botão para adicionar uma nova linha */}
            <Box mt={2}>
                <Button variant="contained" onClick={addNewRow}>
                    Adicionar Plano de Ação
                </Button>
            </Box>
        </Box>
    );
};

export default TabelaDeTarefas;