import React, { useState } from 'react';
import {
    Box,
    Grid,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Select,
    MenuItem,
    Button,
    Tooltip,
} from '@mui/material';
import InfoHeader from '../components/InfoHeader';

type Row = {
    [key: string]: string | number; // Permite acesso dinâmico por chave
    opcao: string;
    impacto: number;
    diferencial: number;
    tendencia: number;
    pontuacao: number;
};

const PontosFortes: React.FC = () => {
    const [rows, setRows] = useState<Row[]>([
        { opcao: 'Equipe qualificada e motivada', impacto: 4, diferencial: 4, tendencia: 4, pontuacao: 64 },
        { opcao: 'Diferenciais competitivos claros (ex.: patentes, exclusividade)', impacto: 3, diferencial: 3, tendencia: 3, pontuacao: 27 },
        { opcao: 'Acesso fácil a financiamento ou crédito', impacto: 2, diferencial: 2, tendencia: 2, pontuacao: 8 },
        { opcao: 'Fluxo de caixa positivo', impacto: 1, diferencial: 1, tendencia: 1, pontuacao: 1 },
        { opcao: 'Alta satisfação e fidelidade dos clientes', impacto: 0, diferencial: 0, tendencia: 0, pontuacao: 0 },
        { opcao: 'Baixa rotatividade de funcionários', impacto: 0, diferencial: 0, tendencia: 0, pontuacao: 0 },
        { opcao: 'Base de clientes leal e diversificada', impacto: 0, diferencial: 0, tendencia: 0, pontuacao: 0 },
        { opcao: 'Capacidade produtiva superior à concorrência', impacto: 0, diferencial: 0, tendencia: 0, pontuacao: 0 },
        { opcao: 'Conhecimento profundo do mercado-alvo', impacto: 0, diferencial: 0, tendencia: 0, pontuacao: 0 },
        { opcao: 'Disponibilidade de capital próprio', impacto: 0, diferencial: 0, tendencia: 0, pontuacao: 0 },
        { opcao: 'Excelente clima organizacional', impacto: 0, diferencial: 0, tendencia: 0, pontuacao: 0 },
        { opcao: 'Gestão eficiente de custos', impacto: 0, diferencial: 0, tendencia: 0, pontuacao: 0 },
        { opcao: 'Liderança forte e experiente', impacto: 0, diferencial: 0, tendencia: 0, pontuacao: 0 },
        { opcao: 'Localização estratégica', impacto: 0, diferencial: 0, tendencia: 0, pontuacao: 0 },
        { opcao: 'Portfólio diversificado e inovador', impacto: 0, diferencial: 0, tendencia: 0, pontuacao: 0 },
        { opcao: 'Presença online forte (SEO, redes sociais, engajamento)', impacto: 0, diferencial: 0, tendencia: 0, pontuacao: 0 },
        { opcao: 'Processos bem estruturados e eficientes', impacto: 0, diferencial: 0, tendencia: 0, pontuacao: 0 },
        { opcao: 'Produtos/serviços com alta qualidade', impacto: 0, diferencial: 0, tendencia: 0, pontuacao: 0 },
        { opcao: 'Reconhecimento da marca no mercado', impacto: 0, diferencial: 0, tendencia: 0, pontuacao: 0 },
        { opcao: 'Relacionamentos fortes com fornecedores e distribuidores', impacto: 0, diferencial: 0, tendencia: 0, pontuacao: 0 },
        { opcao: 'Relacionamentos sólidos com stakeholders', impacto: 0, diferencial: 0, tendencia: 0, pontuacao: 0 },
        { opcao: 'Reputação sólida e confiável', impacto: 0, diferencial: 0, tendencia: 0, pontuacao: 0 },
        { opcao: 'Tecnologia avançada ou exclusiva', impacto: 0, diferencial: 0, tendencia: 0, pontuacao: 0 },
    ]);

    // Função para atualizar os valores
    const handleChange = (index: number, field: string, value: string | number) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        // Atualiza a pontuação automaticamente
        updatedRows[index].pontuacao =
            updatedRows[index].impacto *
            updatedRows[index].diferencial *
            updatedRows[index].tendencia;
        setRows(updatedRows);
    };

    // Adicionar nova linha
    const handleAddRow = () => {
        setRows([
            ...rows,
            { opcao: '', impacto: 1, diferencial: 1, tendencia: 1, pontuacao: 1 },
        ]);
    };

    return (
        <Box>
            {/* Header */}
            <InfoHeader
                title="Pontos Fortes"
                message="Avalie os pontos fortes de sua empresa. Classifique o impacto, diferencial e tendência para cada item."
                isImportant={true}
            />

            {/* Tabela */}
            <Grid container justifyContent="center" sx={{ mt: 4 }}>
                <Grid item xs={12} md={10}>
                    <Paper elevation={3} sx={{ padding: 2 }}>
                        <Typography
                            variant="h6"
                            sx={{ textAlign: 'center', marginBottom: 2, fontWeight: 'bold' }}
                        >
                            PONTOS FORTES
                        </Typography>

                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '40%' }}><strong>Ponto Forte</strong></TableCell>
                                        <TableCell sx={{ width: '15%' }}><strong>Impacto</strong></TableCell>
                                        <TableCell sx={{ width: '15%' }}><strong>Diferencial</strong></TableCell>
                                        <TableCell sx={{ width: '15%' }}><strong>Tendência</strong></TableCell>
                                        <TableCell sx={{ width: '15%' }}><strong>Pontuação</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    minRows={1}
                                                    variant="outlined"
                                                    size="small"
                                                    value={row.opcao}
                                                    onChange={(e) => handleChange(index, 'opcao', e.target.value as string)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    value={row.impacto}
                                                    onChange={(e) => handleChange(index, 'impacto', parseInt(e.target.value as string))}
                                                    fullWidth
                                                    size="small"
                                                >
                                                    {[1, 2, 3, 4].map((value) => (
                                                        <MenuItem key={value} value={value}>
                                                            {value}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    value={row.diferencial}
                                                    onChange={(e) => handleChange(index, 'diferencial', parseInt(e.target.value as string))}
                                                    fullWidth
                                                    size="small"
                                                >
                                                    {[1, 2, 3, 4].map((value) => (
                                                        <MenuItem key={value} value={value}>
                                                            {value}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    value={row.tendencia}
                                                    onChange={(e) => handleChange(index, 'tendencia', parseInt(e.target.value as string))}
                                                    fullWidth
                                                    size="small"
                                                >
                                                    {[1, 2, 3, 4].map((value) => (
                                                        <MenuItem key={value} value={value}>
                                                            {value}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2" textAlign="center">
                                                    {row.pontuacao}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/* Botão para adicionar nova linha */}
                        <Box sx={{ textAlign: 'center', marginTop: 2 }}>
                            <Tooltip title="Adicionar um novo ponto forte">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleAddRow}
                                >
                                    Adicionar Ponto Forte
                                </Button>
                            </Tooltip>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PontosFortes;
