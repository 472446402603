import React, { useState } from 'react';
import { Box } from '@mui/material';
import Departamentos from './Departamentos';
import Colaboradores from './Colaboradores';
import Fundamentos from './Fundamentos';
import PageTitle from '../components/PageTitle';
import { CustomTabs, CustomTab } from '../components/CustomTabs';

const Diretrizes: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ margin: 'auto' }}>
      <PageTitle
        title='DIRETRIZES'
        message='Esta é a parte mais importante da planilha, pois é aqui que você definirá as diretrizes necessárias que ajudarão a gerar informações sobre suas estratégias.'
      />
      <CustomTabs
        value={activeTab}
        onChange={handleTabChange}
        textColor="secondary"
        indicatorColor="secondary"
        variant="fullWidth"
      >
        <CustomTab tabTitle="Fundamentos" content={<Fundamentos />} />
        <CustomTab tabTitle="Departamentos" content={<Departamentos />} />
        <CustomTab tabTitle="Colaboradores" content={<Colaboradores />} />
      </CustomTabs>
    </Box>
  );
};

export default Diretrizes;