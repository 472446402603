import React, { useState } from 'react';
import { Box, Typography, TextField, Grid, Paper, Button } from '@mui/material';
import Empresa from '../types/negocio/Empresa';
import EmpresaService from '../services/empresaService';

const Fundamentos: React.FC = () => {
  const [empresa, setEmpresa] = useState<Empresa>({} as Empresa);
  const empresaService = new EmpresaService();

  const handleChange = (field: keyof Empresa, value: any) => {
    setEmpresa((prev: Empresa) => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSave = async () => {
    try {
      await empresaService.save(empresa);
      console.log("Empresa salva com sucesso!");
    } catch (error) {
      console.error("Erro ao salvar empresa:", error);
    }
  };
  return (
    <Box sx={{ padding: 4, maxWidth: 800, margin: 'auto' }}>
      <Typography variant="h5" gutterBottom>
        Informações básicas para o planejamento
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper sx={{ padding: 2}}>
            <Typography>Nome da empresa</Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={empresa.nome}
              onChange={(e) => handleChange('nome', e.target.value)}
              InputProps={{ style: { color: 'white' } }}
            />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper sx={{ padding: 2 }}>
            <Typography>Ano início do planejamento</Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              value={empresa.anoInicioPlanejamento}
              onChange={(e) => handleChange('anoInicioPlanejamento', Number(e.target.value))}
            />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper sx={{ padding: 2 }}>
            <Typography>Planejar para quantos anos?</Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              value={empresa.planejarQuantosAnos}
              onChange={(e) => handleChange('planejarQuantosAnos', Number(e.target.value))}
            />
          </Paper>
        </Grid>
      </Grid>
      
      <Box mt={4} display="flex" justifyContent="center">
        <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/skg-29jvfsU"
              title="Vídeo explicativo"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
        ></iframe>
      </Box >
      
      <Box mt={4}>
        <Paper sx={{ padding: 2}}>
          <Typography>Missão</Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={empresa.missao}
            onChange={(e) => handleChange('missao', e.target.value)}
            InputProps={{ style: { color: 'white' } }}
          />
          <Typography mt={2}>
            Exemplo:<br></br>
            <strong>Google</strong>: “Organizar as informações do mundo todo e torná-las acessíveis e úteis em caráter universal”.<br></br>
            <strong>Microsoft</strong>: “Ajudar as pessoas e empresas em todo o mundo a concretizarem todo o seu potencial”.<br></br>
            <strong>Vale</strong>: "Transformar recursos naturais em prosperidade e desenvolvimento sustentável."<br></br>
          </Typography>
        </Paper>
      </Box>
      <Box mt={4}>
        <Paper sx={{ padding: 2}}>
          <Typography>Visão</Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={empresa.visao}
            onChange={(e) => handleChange('visao', e.target.value)}
            InputProps={{ style: { color: 'white' } }}
          />
          <Typography mt={2}>
            Exemplo:<br></br>
            <strong>Petrobras</strong>: “Ser reconhecida como a melhor empresa de energia, garantindo o máximo valor para os seus acionistas e para a sociedade”.<br></br>
            <strong>Magazine Luiza</strong>: “Ser a maior e melhor plataforma digital de varejo do Brasil, conectando pessoas e negócios”.<br></br>
            <strong>Amazon</strong>: "Ser a empresa mais centrada no cliente do mundo, onde as pessoas podem encontrar e descobrir qualquer coisa que queiram comprar".<br></br>
          </Typography>
        </Paper>
      </Box>
      <Box mt={4}>
        <Paper sx={{ padding: 2}}>
        <Typography>Valores</Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={empresa.visao}
            onChange={(e) => handleChange('visao', e.target.value)}
            InputProps={{ style: { color: 'white' } }}
          />
          <Typography mt={2}>
            Exemplo:<br></br>
            <strong>Natura</strong>: “Bem-estar bem. Transparência nas relações. Compromisso com a verdade. Sustentabilidade como princípio”.<br></br>
            <strong>Apple</strong>: “Inovação contínua. Excelência em design e simplicidade. Foco na experiência do cliente".<br></br>
            <strong>Tesla</strong>: "Sustentabilidade ambiental. Inovação disruptiva. Ética e excelência no trabalho".<br></br>
          </Typography>
        </Paper>
      </Box>
      <Box mt={4} display="flex" justifyContent="center">
        <Button variant="contained" color="primary" onClick={handleSave}>
          Salvar
        </Button>
      </Box>
    </Box>
  );
};

export default Fundamentos;