import React, { useState } from 'react';
import { Box, Typography, TextField, Grid, Button, Snackbar, Alert } from '@mui/material';

interface Colaborador {
  nomeCompleto: string;
  cargoAtual: string;
  departamento: string;
  celular: string;
  email: string;
  admissao: string;
  formacaoAcademica: string;
  certificacoes: string;
  experienciaProfissional: string;
  treinamentos: string;
  habilidadesTecnicas: string;
  habilidadesComportamentais: string;
  idiomas: string;
}

const CadastroColaborador: React.FC = () => {
  const [colaborador, setColaborador] = useState<Colaborador>({
    nomeCompleto: '',
    cargoAtual: '',
    departamento: '',
    celular: '',
    email: '',
    admissao: '',
    formacaoAcademica: '',
    certificacoes: '',
    experienciaProfissional: '',
    treinamentos: '',
    habilidadesTecnicas: '',
    habilidadesComportamentais: '',
    idiomas: '',
  });

  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleChange = (field: keyof Colaborador, value: string) => {
    setColaborador((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSave = () => {
    const allFieldsFilled = Object.values(colaborador).every((field) => field.trim() !== '');
    if (allFieldsFilled) {
      setSnackbar({ open: true, message: 'Funcionário cadastrado com sucesso!', severity: 'success' });
      console.log('Colaborador salvo:', colaborador);
    } else {
      setSnackbar({ open: true, message: 'Preencha todos os campos!', severity: 'error' });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ padding: 4, maxWidth: 800, margin: 'auto' }}>
      <Typography variant="h5" gutterBottom>
        Cadastro de Colaborador
      </Typography>
      <Grid container spacing={2}>
        {/* Dados pessoais */}
        <Grid item xs={12}>
          <Typography variant="h6">Dados Pessoais</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Nome Completo"
            variant="outlined"
            color="secondary"
            value={colaborador.nomeCompleto}
            onChange={(e) => handleChange('nomeCompleto', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Cargo Atual"
            variant="outlined"
            color="secondary"
            value={colaborador.cargoAtual}
            onChange={(e) => handleChange('cargoAtual', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Departamento"
            variant="outlined"
            color="secondary"
            value={colaborador.departamento}
            onChange={(e) => handleChange('departamento', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Celular"
            variant="outlined"
            color="secondary"
            value={colaborador.celular}
            onChange={(e) => handleChange('celular', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="E-mail"
            variant="outlined"
            color="secondary"
            value={colaborador.email}
            onChange={(e) => handleChange('email', e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Data de Admissão"
            type="date"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            color="secondary"
            value={colaborador.admissao}
            onChange={(e) => handleChange('admissao', e.target.value)}
          />
        </Grid>

        {/* Formação e Experiência */}
        <Grid item xs={12}>
          <Typography variant="h6">Formação e Experiência</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Formação Acadêmica"
            variant="outlined"
            color="secondary"
            value={colaborador.formacaoAcademica}
            onChange={(e) => handleChange('formacaoAcademica', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Certificações"
            variant="outlined"
            color="secondary"
            value={colaborador.certificacoes}
            onChange={(e) => handleChange('certificacoes', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Experiência Profissional"
            multiline
            rows={3}
            variant="outlined"
            color="secondary"
            value={colaborador.experienciaProfissional}
            onChange={(e) => handleChange('experienciaProfissional', e.target.value)}
          />
        </Grid>

        {/* Habilidades e Idiomas */}
        <Grid item xs={12}>
          <Typography variant="h6">Habilidades e Idiomas</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Treinamentos Realizados"
            multiline
            rows={2}
            variant="outlined"
            color="secondary"
            value={colaborador.treinamentos}
            onChange={(e) => handleChange('treinamentos', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Habilidades Técnicas"
            multiline
            rows={2}
            variant="outlined"
            color="secondary"
            value={colaborador.habilidadesTecnicas}
            onChange={(e) => handleChange('habilidadesTecnicas', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Habilidades Comportamentais"
            multiline
            rows={2}
            variant="outlined"
            color="secondary"
            value={colaborador.habilidadesComportamentais}
            onChange={(e) => handleChange('habilidadesComportamentais', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Idiomas"
            variant="outlined"
            color="secondary"
            value={colaborador.idiomas}
            onChange={(e) => handleChange('idiomas', e.target.value)}
          />
        </Grid>
      </Grid>

      {/* Botão Salvar */}
      <Box mt={4} display="flex" justifyContent="center">
        <Button variant="contained" color="primary" onClick={handleSave}>
          Cadastrar
        </Button>
      </Box>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity as any} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CadastroColaborador;