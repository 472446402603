import React from 'react';
import TabelaDeTarefas from '../components/TabelaPlanoDeAcao';

const Pessoas: React.FC = () => {
  const metas = ['Aumentar satisfação', 'Melhorar retenção', 'Treinamento'];
  const colaboradores = ['Paulo', 'Sandra', 'Marcos'];
  const departamentos = ['RH', 'Treinamento'];

  return (
    <TabelaDeTarefas metas={metas} colaboradores={colaboradores} departamentos={departamentos} />
  );
};

export default Pessoas;