// src/App.tsx

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./LoginPage";
import AppLayout from "./AppLayout";
import { ThemeContextProvider } from "./components/theme/ThemeContext";
import Diretrizes from "./pages/Diretrizes";
import Cenario from "./pages/Cenario";
import Swot from "./pages/Swot";
import Metas from "./pages/Metas";
import PlanoDeAcao from "./pages/PlanoDeAcao";
import Avaliacao from "./pages/⁠Avaliacao";
import Graficos from "./pages/Graficos";
import Estrategia from "./pages/Estrategia";
import Relatorios from "./pages/Relatorios";
import LandingPage from "./pages/LandingPage";
// import Convite from "../types/negocio/Convite";
// import SelecaoEmpresa from "./SelecaoEmpresa";

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  // const [isConviteSelecionado, setIsConviteSelecionado] = useState<Convite>();

  useEffect(() => {
    const authStatus = localStorage.getItem("isAuthenticated") === "true";
    // const conviteSelecionado = localStorage.getItem("conviteSelecionado") ? JSON.parse(localStorage.getItem("conviteSelecionado") as string) : null;
    setIsAuthenticated(authStatus);
    // setIsConviteSelecionado(conviteSelecionado);
  }, []);

  return (
    <ThemeContextProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/tw/login" element={<LoginPage />} />

          {/* Rota principal protegida */}
          <Route
            path="/tw"
            element={
              isAuthenticated ? (
                // (isConviteSelecionado ?
                 <AppLayout isAuthenticated={isAuthenticated} />
                // )
              ) : (
                <Navigate to="/tw/login" /> // Redireciona para a página de login caso não esteja autenticado
              )
            }
          >
            {/* <Route path="/tw/empresa" element={<SelecaoEmpresa />} /> */}
            <Route path="/tw/diretrizes" element={<Diretrizes />} />
            <Route path="/tw/cenario" element={<Cenario />} />
            <Route path="/tw/swot" element={<Swot />} />
            <Route path="/tw/metas" element={<Metas />} />
            <Route path="/tw/estrategia" element={<Estrategia />} />
            <Route path="/tw/plano-de-acao" element={<PlanoDeAcao />} />
            <Route path="/tw/avaliacao" element={<Avaliacao />} />
            <Route path="/tw/graficos" element={<Graficos />} />
            <Route path="/tw/relatorios" element={<Relatorios />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </ThemeContextProvider>
  );
};

export default App;