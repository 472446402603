import { Box, IconButton } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import React from "react";
import { useNavigate } from "react-router-dom";

const features = [
  {
    title: "Definição de Metas",
    description:
      "Estabeleça objetivos claros e mensuráveis para orientar o crescimento da sua organização.",
    icon: "🎯", // Ícone representando metas
  },
  {
    title: "Análise SWOT Integrada",
    description:
      "Identifique forças, fraquezas, oportunidades e ameaças para embasar suas decisões estratégicas.",
    icon: "📊", // Ícone representando análise
  },
  {
    title: "Monitoramento de Indicadores (KPIs)",
    description:
      "Acompanhe o desempenho em tempo real para garantir o cumprimento das metas estabelecidas.",
    icon: "📈", // Ícone representando KPIs
  },
  {
    title: "Gestão de Projetos",
    description:
      "Organize e gerencie iniciativas estratégicas com eficiência, assegurando a execução alinhada aos objetivos corporativos.",
    icon: "📋", // Ícone representando projetos
  },
  {
    title: "Colaboração em Equipe",
    description:
      "Facilite a comunicação e o trabalho conjunto entre membros da equipe, promovendo um ambiente colaborativo e produtivo.",
    icon: "🤝", // Ícone representando colaboração
  },
  {
    title: "Automatização de Processos",
    description:
      "Utilize fluxos automatizados para reduzir tarefas manuais e aumentar a eficiência operacional.",
    icon: "⚙️", // Ícone representando automação
  },
];

const renderFeatures = () =>
  features.map((feature, index) => (
    <div key={index} style={styles.featureCard}>
      <div style={styles.iconContainer}>
        <span style={styles.icon}>{feature.icon}</span>
      </div>
      <h3 style={styles.featureTitle}>{feature.title}</h3>
      <p style={styles.featureDescription}>{feature.description}</p>
    </div>
  ));

const LandingPage: React.FC = () => {

  const navigate = useNavigate();

  const handleEntrar = () => {
    navigate('/tw/login');
  };

  return (
    <div style={styles.container}>
      {/* Header Section with Logo */}
      <Box style={styles.header}>
        <img src="logo512.png" alt="Logo da Plataforma" style={styles.logo} />
        <h1 style={styles.heroTitle}>
          Planejamento Estratégico <br/>Simplificado
        </h1>
        <IconButton onClick={handleEntrar}>
          <LoginIcon /> Entrar
        </IconButton>
      </Box>
      {/* Hero Section */}
      <section style={styles.hero}>
        <div style={styles.heroContent}>
          <p style={styles.heroSubtitle}>
          Simplifique o desenvolvimento e a gestão do planejamento estratégico 
          da sua empresa. Construa cenários, defina indicadores e alcance 
          objetivos com uma plataforma intuitiva, acessível e com total autonomia.
          </p>
        </div>
      </section>
      {/* Features Section */}
      <div style={styles.features}>
        <h2 style={styles.sectionTitle}>Principais Recursos</h2>
        <div style={styles.featureGrid}>{renderFeatures()}</div>
      </div>
      ;{/* Video Section */}
      <div style={styles.videoSection}>
        <h2 style={styles.sectionTitle}>Como Podemos Ajudar</h2>
        <p style={styles.text}>
          Assista ao vídeo abaixo para entender como nossa plataforma pode
          transformar o planejamento estratégico da sua empresa, ajudando-a 
          a alcançar seus objetivos de forma eficiente e eficaz:
        </p>
        <div style={styles.videoContainer}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/1EwsC6QOWBE"
            title="Vídeo explicativo"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      {/* Call to Action Section */}
      <section style={styles.cta}>
        <h2 style={styles.ctaText}>Lançamento em Breve!</h2>
        <p style={styles.text}>
          Fique atento para mais atualizações e prepare-se para revolucionar o
          planejamento estratégico da sua organização com nossa plataforma.
        </p>
      </section>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    fontFamily: "Arial, sans-serif",
    color: "white",
    backgroundColor: "#f5f5f5",
    lineHeight: "1.6",
    margin: 0,
    padding: 0,
  },
  header: {
    padding: "10px 20px",
    textAlign: "center",
    backgroundColor: "#1A2535",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  logo: {
    height: "120px", // Ajuste no tamanho da logo
  },
  hero: {
    background: "#1A2535",
    color: "#fff",
    textAlign: "center",
    padding: "10px 20px", // Reduzi o padding aqui
  },
  heroContent: {
    maxWidth: "800px",
    margin: "0 auto",
  },
  heroTitle: {
    fontSize: "3.5em", // Fonte maior para o título
    margin: "0 0 20px",
    lineHeight: "1.2",
  },
  heroSubtitle: {
    fontSize: "1.8em", // Fonte maior para o subtítulo
    marginBottom: "20px", // Reduzi o espaço inferior
  },
  features: {
    padding: "40px 20px", // Padding menor na seção
    textAlign: "center",
  },
  sectionTitle: {
    fontSize: "2.5em", // Fonte maior para o título das seções
    marginBottom: "20px", // Espaço reduzido
    color: "#042E4B",
  },
  featureGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    gap: "20px",
    maxWidth: "1000px",
    margin: "0 auto",
  },
  featureCard: {
    background: "#fff",
    padding: "15px", // Reduzi o padding interno
    borderRadius: "5px",
    textAlign: "center",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    border: "1px solid #042E4B",
  },
  iconContainer: {
    marginBottom: "10px",
  },
  icon: {
    fontSize: "2.5em", // Ícones maiores
    color: "#EF453D",
  },
  featureTitle: {
    fontSize: "1.6em", // Fonte maior para o título das features
    marginBottom: "10px",
    color: "#042E4B",
  },
  featureDescription: {
    fontSize: "1.1em", // Fonte maior para o texto descritivo
    color: "#333",
  },
  videoSection: {
    background: "#f5f5f5",
    padding: "40px 20px", // Menos espaçamento
    textAlign: "center",
  },
  text: {
    fontSize: "1.4em", // Fonte maior para o texto comum
    marginBottom: "15px", // Reduzi o espaço inferior
  },
  videoContainer: {
    textAlign: "center",
  },
  cta: {
    background: "#EF453D",
    color: "#fff",
    textAlign: "center",
    padding: "40px 20px", // Padding ajustado
  },
  ctaText: {
    fontSize: "2em", // Destaque no CTA
    marginBottom: "15px",
  },
};

export default LandingPage;
