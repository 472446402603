import React, { useState } from 'react';
import { Box } from '@mui/material';
import PageTitle from '../components/PageTitle';
import { CustomTabs, CustomTab } from '../components/CustomTabs';
import PontosFortes from './PontosFortes';
import PontosFracos from './PontosFracos';

const Swot: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ margin: 'auto' }}>
      <PageTitle
        title='SWOT'
        message='Tenha uma visão geral da sua estratégia e como ela vem sendo implementada. A partir daqui, você poderá ter insights sobre as ações que devem ser implementadas ou ajustadas.'
      />
      <CustomTabs
        value={activeTab}
        onChange={handleTabChange}
        textColor="secondary"
        indicatorColor="secondary"
        variant="fullWidth"
      >
        <CustomTab tabTitle="Pontos Fortes" content={<PontosFortes />} />
        <CustomTab tabTitle="Pontos Fracos" content={<PontosFracos />} />
      </CustomTabs>
    </Box>
  );
};

export default Swot;