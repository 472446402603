import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAcMZcP2qC9C0lpr96aeEfjOwMn8NPRsa4",
  authDomain: "thinkwayapp.firebaseapp.com",
  projectId: "thinkwayapp",
  storageBucket: "thinkwayapp.firebasestorage.app",
  messagingSenderId: "316514856746",
  appId: "1:316514856746:web:04a330586ceae8da267853",
  measurementId: "G-JE9NSLH2JN"
};

/**
 * Nunca chamar
 *  Storage
 *  Firestore
 *  Rtbd
 * Diretamente pelo frontend sempre utilizar/criar uma function para tal
 */


export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const functions = getFunctions(app);
export const auth = getAuth();

if (window.location.hostname === "localhost") {
  connectFunctionsEmulator(functions, "localhost", 9091);
}