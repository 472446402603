import React from 'react';
import TabelaDeTarefas from '../components/TabelaPlanoDeAcao';

const Vendas: React.FC = () => {
  const metas = ['Aumentar leads', 'Melhorar taxa de conversão', 'Expandir mercado'];
  const colaboradores = ['João', 'Ana', 'Carlos'];
  const departamentos = ['Vendas', 'Marketing'];

  return (
    <TabelaDeTarefas metas={metas} colaboradores={colaboradores} departamentos={departamentos} />
  );
};

export default Vendas;