import React, { useState } from 'react';
import { Box } from '@mui/material';
import MetasGlobais from './MetasGlobais';
import MetasEspecificas from './MetasEspecificas';
import PageTitle from '../components/PageTitle';
import { CustomTabs, CustomTab } from '../components/CustomTabs';

const Metas: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ margin: 'auto' }}>
      <PageTitle
        title='METAS'
        message='Esta é a parte que você definirá as metas necessárias que ajudarão a gerar informações sobre suas estratégias.'
      />
      <CustomTabs
        value={activeTab}
        onChange={handleTabChange}
        textColor="secondary"
        indicatorColor="secondary"
        variant="fullWidth"
      >
        <CustomTab tabTitle="Metas globais" content={<MetasGlobais />} />
        <CustomTab tabTitle="Metas específicas" content={<MetasEspecificas />} />
      </CustomTabs>
    </Box>
  );
};

export default Metas;