import { functions } from "../firebaseConfig";
import { httpsCallable } from "firebase/functions";
import AbstractModel from "../types/negocio/AbstractModel";

export default class AbstractService<T extends AbstractModel> {

  private functionPrefix: string;

  constructor(functionPrefix: string) {
    this.functionPrefix = functionPrefix;
  }

  async getById(id: string): Promise<T | null> {
    return new Promise((resolve, reject) => {
      const getById = httpsCallable(functions, this.functionPrefix + 'GetById');
      getById({ id: id })
        .then((result) => {
          resolve(result.data as T);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getAll(): Promise<T[]> {
    return new Promise((resolve, reject) => {
      const getAll = httpsCallable(functions, this.functionPrefix + 'GetAll');
      getAll()
        .then((result) => {
          resolve(result.data as T[]);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  async call<TR>(functionName: string, body: string): Promise<TR> {
    return new Promise((resolve, reject) => {
      const functionRef = httpsCallable(functions, this.functionPrefix + functionName);
      functionRef(body)
        .then((result) => {
          resolve(result.data as TR);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async save(data: T): Promise<{ success: boolean; message: string }> {
    return new Promise((resolve, reject) => {
      const save = httpsCallable(functions, this.functionPrefix + 'Save');
      save({ id: data.id, data: data })
        .then((result) => {
          resolve(result.data as { success: boolean; message: string });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async delete(id: string): Promise<{ success: boolean; message: string }> {
    return new Promise((resolve, reject) => {
      const deleteFunc = httpsCallable(functions, this.functionPrefix + 'Delete');
      deleteFunc({ id: id })
        .then((result) => {
          resolve(result.data as { success: boolean; message: string });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async update(id: string, data: Partial<T>): Promise<{ success: boolean; message: string }> {
    return new Promise((resolve, reject) => {
      const update = httpsCallable(functions, this.functionPrefix + 'Update');
      update({ id: id, data: data })
        .then((result) => {
          resolve(result.data as { success: boolean; message: string });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}