import React, { useState } from 'react';
import { Box } from '@mui/material';
import PageTitle from '../components/PageTitle';
import { CustomTabs, CustomTab } from '../components/CustomTabs';
import Politico from './Politico';
import Economico from './Economico';
import Social from './Social';
import Tecnologico from './Tecnologico';
import Ambiental from './Ambiental';
import Legal from './Legal';

const Cenario: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ margin: 'auto' }}>
      <PageTitle
        title='CENÁRIO'
        message='Tenha uma visão geral da sua estratégia e como ela vem sendo implementada. A partir daqui, você poderá ter insights sobre as ações que devem ser implementadas ou ajustadas.'
      />
      <CustomTabs
        value={activeTab}
        onChange={handleTabChange}
        textColor="secondary"
        indicatorColor="secondary"
        variant="fullWidth"
      >
        <CustomTab tabTitle="Político" content={<Politico />} />
        <CustomTab tabTitle="Econômico" content={<Economico />} />
        <CustomTab tabTitle="Social" content={<Social />} />
        <CustomTab tabTitle="Tecnológico" content={<Tecnologico />} />
        <CustomTab tabTitle="Ambiental" content={<Ambiental />} />
        <CustomTab tabTitle="Legal" content={<Legal />} />
      </CustomTabs>
    </Box>
  );
};

export default Cenario;