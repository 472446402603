import React, { useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Button,
  Tooltip,
} from '@mui/material';
import InfoHeader from '../components/InfoHeader';

type Row = {
  [key: string]: string | number;
  opcao: string;
  gravidade: number;
  urgencia: number;
  tendencia: number;
  pontuacao: number;
};

const PontosFracos: React.FC = () => {
  const [rows, setRows] = useState<Row[]>([
    { opcao: 'Alta rotatividade de funcionários', gravidade: 4, urgencia: 4, tendencia: 4, pontuacao: 64 },
    { opcao: 'Equipamentos obsoletos ou ineficientes', gravidade: 3, urgencia: 3, tendencia: 3, pontuacao: 27 },
    { opcao: 'Preço pouco competitivo', gravidade: 3, urgencia: 2, tendencia: 4, pontuacao: 24 },
    { opcao: 'Dependência de empréstimos ou crédito de terceiros', gravidade: 1, urgencia: 2, tendencia: 2, pontuacao: 4 },
    { opcao: 'Reputação negativa ou problemas de imagem', gravidade: 0, urgencia: 0, tendencia: 0, pontuacao: 0 },
    { opcao: 'Relacionamentos frágeis com fornecedores ou parceiros', gravidade: 0, urgencia: 0, tendencia: 0, pontuacao: 0 },
    { opcao: 'Reclamações frequentes sobre a qualidade', gravidade: 0, urgencia: 0, tendencia: 0, pontuacao: 0 },
    { opcao: 'Presença online fraca ou inexistente', gravidade: 0, urgencia: 0, tendencia: 0, pontuacao: 0 },
    { opcao: 'Portfólio limitado ou ultrapassado', gravidade: 0, urgencia: 0, tendencia: 0, pontuacao: 0 },
    { opcao: 'Margens de lucro reduzidas', gravidade: 0, urgencia: 0, tendencia: 0, pontuacao: 0 },
    { opcao: 'Localização desfavorável', gravidade: 0, urgencia: 0, tendencia: 0, pontuacao: 0 },
    { opcao: 'Liderança fraca ou inexperiente', gravidade: 0, urgencia: 0, tendencia: 0, pontuacao: 0 },
    { opcao: 'Gargalos nos processos produtivos', gravidade: 0, urgencia: 0, tendencia: 0, pontuacao: 0 },
    { opcao: 'Fluxo de caixa instável ou negativo', gravidade: 0, urgencia: 0, tendencia: 0, pontuacao: 0 },
    { opcao: 'Falta de qualificação ou treinamento na equipe', gravidade: 0, urgencia: 0, tendencia: 0, pontuacao: 0 },
    { opcao: 'Falta de inovação ou diferenciação', gravidade: 0, urgencia: 0, tendencia: 0, pontuacao: 0 },
    { opcao: 'Falta de compreensão sobre as necessidades do mercado', gravidade: 0, urgencia: 0, tendencia: 0, pontuacao: 0 },
    { opcao: 'Dificuldade em atrair investidores', gravidade: 0, urgencia: 0, tendencia: 0, pontuacao: 0 },
    { opcao: 'Dependência excessiva de fornecedores específicos', gravidade: 0, urgencia: 0, tendencia: 0, pontuacao: 0 },
    { opcao: 'Dependência de um único segmento de mercado', gravidade: 0, urgencia: 0, tendencia: 0, pontuacao: 0 },
    { opcao: 'Dependência de poucos clientes-chave', gravidade: 0, urgencia: 0, tendencia: 0, pontuacao: 0 },
    { opcao: 'Base de clientes reduzida ou pouco diversificada', gravidade: 0, urgencia: 0, tendencia: 0, pontuacao: 0 },
    { opcao: 'Baixo reconhecimento da marca', gravidade: 0, urgencia: 0, tendencia: 0, pontuacao: 0 },
    { opcao: 'Baixo engajamento ou insatisfação interna', gravidade: 0, urgencia: 0, tendencia: 0, pontuacao: 0 },
  ]);

  const handleChange = (index: number, field: string, value: string | number) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    updatedRows[index].pontuacao =
      updatedRows[index].gravidade * updatedRows[index].urgencia * updatedRows[index].tendencia;
    setRows(updatedRows);
  };

  const handleAddRow = () => {
    setRows([...rows, { opcao: '', gravidade: 1, urgencia: 1, tendencia: 1, pontuacao: 1 }]);
  };

  return (
    <Box>
      <InfoHeader
        title="Pontos Fracos"
        message="Avalie os pontos fracos de sua empresa. Classifique gravidade, urgência e tendência para cada item."
        isImportant
      />
      <Grid container justifyContent="center" sx={{ mt: 4 }}>
        <Grid item xs={12} md={10}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', marginBottom: 2, fontWeight: 'bold' }}
            >
              PONTOS FRACOS
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: '40%' }}><strong>Ponto Fraco</strong></TableCell>
                    <TableCell><strong>Gravidade</strong></TableCell>
                    <TableCell><strong>Urgência</strong></TableCell>
                    <TableCell><strong>Tendência</strong></TableCell>
                    <TableCell><strong>Pontuação</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <TextField
                          fullWidth
                          multiline
                          minRows={1}
                          variant="outlined"
                          size="small"
                          value={row.opcao}
                          onChange={(e) => handleChange(index, 'opcao', e.target.value)}
                        />
                      </TableCell>
                      <TableCell>
                        <Select
                          value={row.gravidade}
                          onChange={(e) => handleChange(index, 'gravidade', parseInt(e.target.value as string))}
                          fullWidth
                          size="small"
                        >
                          {[1, 2, 3, 4].map((value) => (
                            <MenuItem key={value} value={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Select
                          value={row.urgencia}
                          onChange={(e) => handleChange(index, 'urgencia', parseInt(e.target.value as string))}
                          fullWidth
                          size="small"
                        >
                          {[1, 2, 3, 4].map((value) => (
                            <MenuItem key={value} value={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Select
                          value={row.tendencia}
                          onChange={(e) => handleChange(index, 'tendencia', parseInt(e.target.value as string))}
                          fullWidth
                          size="small"
                        >
                          {[1, 2, 3, 4].map((value) => (
                            <MenuItem key={value} value={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" textAlign="center">
                          {row.pontuacao}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ textAlign: 'center', marginTop: 2 }}>
              <Tooltip title="Adicionar um novo ponto fraco">
                <Button variant="contained" color="primary" onClick={handleAddRow}>
                  Adicionar Ponto Fraco
                </Button>
              </Tooltip>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PontosFracos;