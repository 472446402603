import React, { useState } from 'react';
import { Box } from '@mui/material';
import Financas from './Financas';
import Vendas from './Vendas';
import ProcessosProducao from './ProcessosProducao';
import Pessoas from './Pessoas';
import PageTitle from '../components/PageTitle';
import { CustomTabs, CustomTab } from '../components/CustomTabs';

const PlanoDeAcao: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ margin: 'auto' }}>
      <PageTitle
        title="PLANO DE AÇÃO"
        message="Com sua estratégia definida, chegou a hora de traçar um plano de ação para que seus objetivos sejam efetivamente alcançados."
      />
      <CustomTabs
        value={activeTab}
        onChange={handleTabChange}
        textColor="secondary"
        indicatorColor="secondary"
        variant="fullWidth"
      >
        <CustomTab tabTitle="Finanças" content={<Financas />} />
        <CustomTab tabTitle="Vendas" content={<Vendas />} />
        <CustomTab tabTitle="Processos / Produção" content={<ProcessosProducao />} />
        <CustomTab tabTitle="Pessoas" content={<Pessoas />} />
      </CustomTabs>
    </Box>
  );
};

export default PlanoDeAcao;