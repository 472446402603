import React, { useState } from 'react';
import InfoHeader from '../components/InfoHeader';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  TextField,
  Button,
} from '@mui/material';

const Ambiental: React.FC = () => {
  // Estado inicial da tabela com opções preenchidas
  const [rows, setRows] = useState([
    { opcao: 'Exigências crescentes por práticas sustentáveis e produtos ecológicos.', impacto: 'Muito alto', fator: 'Ameaça' },
    { opcao: 'Políticas de descarte e reciclagem de resíduos.', impacto: 'Muito baixo', fator: 'Oportunidade' },
    { opcao: 'Disponibilidade de recursos naturais essenciais ao negócio.', impacto: 'Baixo', fator: 'Ameaça' },
    { opcao: 'Impacto de desastres naturais na operação ou na cadeia de suprimentos.', impacto: 'Médio', fator: 'Oportunidade' },
    { opcao: 'Adoção de fontes de energia renováveis e redução de emissões.', impacto: 'Alto', fator: 'Ameaça' },
    { opcao: 'Impactos das mudanças climáticas no setor (como sazonalidade ou transporte).', impacto: 'Muito alto', fator: 'Oportunidade' },
    { opcao: 'Conscientização do consumidor sobre responsabilidade ambiental.', impacto: 'Nenhum', fator: 'Oportunidade' },
  ]);

  // Funções para manipular os dados
  const handleOpcaoChange = (index: number, value: string) => {
    const updatedRows = [...rows];
    updatedRows[index].opcao = value;
    setRows(updatedRows);
  };

  const handleImpactChange = (index: number, value: string) => {
    const updatedRows = [...rows];
    updatedRows[index].impacto = value;
    setRows(updatedRows);
  };

  const handleFatorChange = (index: number, value: string) => {
    const updatedRows = [...rows];
    updatedRows[index].fator = value;
    setRows(updatedRows);
  };

  // Função para adicionar uma nova linha
  const handleAddRow = () => {
    setRows([...rows, { opcao: '', impacto: '', fator: '' }]);
  };

  return (
    <Box>
      {/* Header */}
      <InfoHeader
        title="Análise Ambiental"
        message="Esta tela permite avaliar os fatores ambientais que podem impactar sua empresa. Classifique o impacto e o fator para cada opção."
        isImportant={true}
      />

      {/* Tabela */}
      <Grid container justifyContent="center" sx={{ mt: 4 }}>
        <Grid item xs={12} md={10}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', marginBottom: 2, fontWeight: 'bold' }}
            >
              AMBIENTAL
            </Typography>

            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: '50%' }}><strong>Opção</strong></TableCell>
                    <TableCell sx={{ width: '25%' }}><strong>Intensidade</strong></TableCell>
                    <TableCell sx={{ width: '25%' }}><strong>Fator</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <TextField
                          fullWidth
                          multiline
                          minRows={1}
                          variant="outlined"
                          size="small"
                          value={row.opcao}
                          onChange={(e) => handleOpcaoChange(index, e.target.value)}
                          sx={{
                            '& .MuiInputBase-root': {
                              overflowWrap: 'break-word',
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Select
                          value={row.impacto}
                          onChange={(e) => handleImpactChange(index, e.target.value)}
                          fullWidth
                          size="small"
                        >
                          <MenuItem value=""><em>Selecionar</em></MenuItem>
                          <MenuItem value="Nenhum">Nenhum</MenuItem>
                          <MenuItem value="Muito baixo">Muito baixo</MenuItem>
                          <MenuItem value="Baixo">Baixo</MenuItem>
                          <MenuItem value="Médio">Médio</MenuItem>
                          <MenuItem value="Alto">Alto</MenuItem>
                          <MenuItem value="Muito alto">Muito alto</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Select
                          value={row.fator}
                          onChange={(e) => handleFatorChange(index, e.target.value)}
                          fullWidth
                          size="small"
                        >
                          <MenuItem value=""><em>Selecionar</em></MenuItem>
                          <MenuItem value="Oportunidade">Oportunidade</MenuItem>
                          <MenuItem value="Ameaça">Ameaça</MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Botão para adicionar nova linha */}
            <Box sx={{ textAlign: 'center', marginTop: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddRow}
              >
                Adicionar Indicador
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Ambiental;