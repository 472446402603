import React, { useState } from 'react';
import InfoHeader from '../components/InfoHeader';
import { Box, TextField, Grid, Paper, Button, List, ListItem, ListItemText, IconButton, Card } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const Departamentos: React.FC = () => {
  const [departamentos, setDepartamentos] = useState<string[]>([]);
  const [showInput, setShowInput] = useState(false);
  const [newDepartamento, setNewDepartamento] = useState('');

  const handleAddDepartamento = () => {
    if (newDepartamento.trim()) {
      setDepartamentos([...departamentos, newDepartamento]);
      setNewDepartamento('');
      setShowInput(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewDepartamento(e.target.value);
  };

  const handleDeleteDepartamento = (indexToDelete: number) => {
    const updateDepartamentos = departamentos.filter((_, index) => index !== indexToDelete);
    setDepartamentos(updateDepartamentos);
  };

  return (
    <Box>
      <InfoHeader 
        title='Departamentos'
        message='Cadastre os departamentos existentes na sua empresa. Este cadastro será utilizado no controle e gestão dos seus planos de ação.'
        isImportant={true}
      />
      <Box 
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          mt: 4
        }}
      >
        <Grid 
          container 
          justifyContent="center"
        >
          <Grid 
            item 
            xs={14} 
            sm={12} 
            md={10} 
            lg={8} 
            xl={6}
          >
            <Paper 
              elevation={3} 
              sx={{ 
                padding: 2, 
                maxWidth: { xs: '95%', sm: '90%', md: '80%', lg: '70%', xl: '60%' }, 
                margin: 'auto' 
              }}
            >
              <Card
                color='secondary'
                sx={{
                  padding: 1,
                  textAlign: 'center',
                  backgroundColor: (theme) => theme.palette.secondary.main,
                  color: 'white',
                }}
              >
                Cadastro de Departamentos
              </Card>

              <List sx={{ marginTop: 2 }}>
                {departamentos.map((departamento, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDeleteDepartamento(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={departamento} />
                  </ListItem>
                ))}
              </List>
              {showInput && (
                <Box sx={{ marginTop: 2 }}>
                  <TextField
                    fullWidth
                    label="Digite o nome do departamento"
                    value={newDepartamento}
                    onChange={handleInputChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleAddDepartamento();
                      }
                    }}
                    autoFocus
                  />
                  <Button
                    variant="contained"
                    sx={{ marginTop: 2 }}
                    fullWidth
                    onClick={handleAddDepartamento}
                  >
                    Adicionar
                  </Button>
                </Box>
              )}
              {!showInput && (
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    marginTop: 2
                  }}
                  onClick={() => setShowInput(true)}
                >
                  Adicionar departamento
                </Button>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Departamentos;