// src/AppLayout.tsx

import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  IconButton,
  AppBar,
  Toolbar,
  CssBaseline,
} from '@mui/material';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import BarChartIcon from '@mui/icons-material/BarChart';
import UpdateIcon from '@mui/icons-material/Update';
import LogoutIcon from '@mui/icons-material/Logout';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import InsightsIcon from '@mui/icons-material/Insights';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SummarizeIcon from '@mui/icons-material/Summarize';
import DescriptionIcon from '@mui/icons-material/Description';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { useThemeContext } from './components/theme/ThemeContext';

interface AppLayoutProps {
  isAuthenticated: boolean;
}

const drawerWidth = 240;

const AppLayout: React.FC<AppLayoutProps> = ({ isAuthenticated }) => {
  const [open, setOpen] = useState(true);
  const { toggleTheme, mode } = useThemeContext();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    navigate('/landing-page');
  };

  if (!isAuthenticated) return <Outlet />;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      {/* TopBar com tema e ajuste de posição responsivo */}
      <AppBar
        position="fixed"
        sx={{
          width: open ? `calc(100% - ${drawerWidth}px)` : '100%',
          ml: open ? `${drawerWidth}px` : 0,
          transition: 'width 0.3s, margin-left 0.3s',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => setOpen(!open)}
            sx={{ mr: 2 }}
          >
            {open ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
          <img src='/logo192.png' width="50px" alt="Logo Think Way" />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, ml: '20px' }}>
            Think Way
          </Typography>
          <IconButton color="inherit" onClick={toggleTheme}>
            {mode === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer expansível com ícones */}
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          width: open ? drawerWidth : 60,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: open ? drawerWidth : 60,
            boxSizing: 'border-box',
            transition: 'width 0.3s',
          },
        }}
      >
        {open && (
          <Typography variant="h6" component="div" sx={{ m: 2 }}>
            Menu
          </Typography>
        )}
        <List>
          <ListItem onClick={() => setOpen(!open)} sx={{ textDecoration: 'none', display: open ? 'none' : 'block' }} >
            <ListItemIcon>
                <MenuIcon />
              </ListItemIcon>
          </ListItem>

          <ListItem component={Link} to="/tw/diretrizes">
            <ListItemIcon>
              <FactCheckIcon color='secondary' />
            </ListItemIcon>
            <ListItemText secondary="Diretrizes" sx={{ textDecoration: 'none', display: open ? 'block' : 'none' }} />
          </ListItem>

          <ListItem component={Link} to="/tw/cenario">
            <ListItemIcon>
              <DataUsageIcon color='secondary' />
            </ListItemIcon>
            <ListItemText secondary="Cenário" sx={{ textDecoration: 'none', display: open ? 'block' : 'none' }} />
          </ListItem>

          <ListItem component={Link} to="/tw/swot">
            <ListItemIcon>
              <TroubleshootIcon color='secondary' />
            </ListItemIcon>
            <ListItemText secondary="Swot" sx={{ textDecoration: 'none', display: open ? 'block' : 'none' }} />
          </ListItem>

          <ListItem component={Link} to="/tw/metas">
            <ListItemIcon>
              <UpdateIcon color='secondary' />
            </ListItemIcon>
            <ListItemText secondary="Metas" sx={{ textDecoration: 'none', display: open ? 'block' : 'none' }} />
          </ListItem>

          <ListItem component={Link} to="/tw/plano-de-acao">
            <ListItemIcon>
              <AssignmentTurnedInIcon color='secondary' />
            </ListItemIcon>
            <ListItemText secondary="Plano de Ação" sx={{ textDecoration: 'none', display: open ? 'block' : 'none' }} />
          </ListItem>

          <ListItem component={Link} to="/tw/estrategia">
            <ListItemIcon>
              <InsightsIcon color='secondary' />
            </ListItemIcon>
            <ListItemText secondary="Estratégia" sx={{ textDecoration: 'none', display: open ? 'block' : 'none' }} />
          </ListItem>

          <ListItem component={Link} to="/tw/avaliacao">
            <ListItemIcon>
              <BarChartIcon color='secondary' />
            </ListItemIcon>
            <ListItemText secondary="Avaliação" sx={{ textDecoration: 'none', display: open ? 'block' : 'none' }} />
          </ListItem>

          <ListItem component={Link} to="/tw/graficos">
            <ListItemIcon>
              <SummarizeIcon color='secondary' />
            </ListItemIcon>
            <ListItemText secondary="Gráficos" sx={{ textDecoration: 'none', display: open ? 'block' : 'none' }} />
          </ListItem>

          <ListItem component={Link} to="/tw/relatorios">
            <ListItemIcon>
              <DescriptionIcon color='secondary' />
            </ListItemIcon>
            <ListItemText secondary="Relatórios" sx={{ textDecoration: 'none', display: open ? 'block' : 'none' }} />
          </ListItem>    

          <ListItem component={Link} onClick={handleLogout} to="/login">
            <ListItemIcon>
              <LogoutIcon color='secondary' />
            </ListItemIcon>
            <ListItemText secondary="Logout" sx={{ textDecoration: 'none', display: open ? 'block' : 'none' }} />
          </ListItem>
        </List>
      </Drawer>

      {/* Conteúdo principal */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          mt: '64px',
          ml: '10px',
          transition: 'margin-left 0.3s',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default AppLayout;