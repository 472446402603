import React from 'react';
import TabelaDeTarefas from '../components/TabelaPlanoDeAcao';

const Financas: React.FC = () => {
  const metas = ['Reduzir custos', 'Aumentar receita', 'Melhorar eficiência'];
  const colaboradores = ['Vinicius', 'Mateus', 'Marco', 'Gab'];
  const departamentos = ['Financeiro', 'Vendas', 'RH', 'Marketing'];

  return (
    <TabelaDeTarefas metas={metas} colaboradores={colaboradores} departamentos={departamentos} />
  );
};

export default Financas;