import React from 'react';
import TabelaDeTarefas from '../components/TabelaPlanoDeAcao';

const ProcessosProducao: React.FC = () => {
  const metas = ['Reduzir desperdício', 'Melhorar qualidade', 'Otimizar processos'];
  const colaboradores = ['Pedro', 'Carla', 'Luiza'];
  const departamentos = ['Produção', 'Manutenção'];

  return (
    <TabelaDeTarefas metas={metas} colaboradores={colaboradores} departamentos={departamentos} />
  );
};

export default ProcessosProducao;